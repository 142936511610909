import {useNavigate, useParams} from 'react-router-dom';
import {useSurvey, useUpdateSurvey} from '../../hooks';
import {generateSurveyAnswers} from '../../utils';

export const TwoStageGreatPickup = () => {
  const {surveyId} = useParams();
  const {data: survey} = useSurvey(surveyId);
  const navigate = useNavigate();
  const {mutate, isLoading} = useUpdateSurvey();

  let handleCheckRadioOne = () => {
    if (isLoading) return false;
    mutate({
      successCallback: () => navigate('../positive/stage-three'),
      id: surveyId,
      IsCompleted: true,
      EndTime: new Date(),
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'LikeBest',
        0
      )
    });
  };
  let handleCheckRadioTwo = () => {
    if (isLoading) return false;
    mutate({
      successCallback: () => navigate('../positive/stage-three'),
      id: surveyId,
      IsCompleted: true,
      EndTime: new Date(),
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'LikeBest',
        1
      )
    });
  };
  let handleCheckRadioThree = () => {
    if (isLoading) return false;
    mutate({
      successCallback: () => navigate('../positive/stage-three'),
      id: surveyId,
      IsCompleted: true,
      EndTime: new Date(),
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'LikeBest',
        2
      )
    });
  };
  let handleCheckRadioFour = () => {
    if (isLoading) return false;
    mutate({
      successCallback: () => navigate('../positive/stage-three'),
      id: surveyId,
      IsCompleted: true,
      EndTime: new Date(),
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'LikeBest',
        3
      )
    });
  };

  const isRadioChecked = (surveyArr = [], value) => {
    return surveyArr.find((el) => el.Name === 'LikeBest')?.Value === value;
  };

  return (
    <>
      <div className='survey__step secondStepGreat'>
        <div className='survey__title lg'>Alright!</div>
        <div className='survey__text'>What did you like best?</div>
        <div className='survey__radioboxes'>
          <label className='radiobox' htmlFor='greatRadioOne'>
            <input
              type='radio'
              name='radioStepGreat'
              id='greatRadioOne'
              checked={isRadioChecked(survey?.data?.SurveyAnswers, 0)}
              onChange={handleCheckRadioOne}
            />
            <span className='radiobox__mark'></span>
            <span className='radiobox__title'>The order was ready on time</span>
          </label>
          <label className='radiobox' htmlFor='greatRadioTwo'>
            <input
              type='radio'
              name='radioStepGreat'
              id='greatRadioTwo'
              checked={isRadioChecked(survey?.data?.SurveyAnswers, 1)}
              onChange={handleCheckRadioTwo}
            />
            <span className='radiobox__mark'></span>
            <span className='radiobox__title'>
              I received exactly what I ordered
            </span>
          </label>
          <label className='radiobox' htmlFor='greatRadioThree'>
            <input
              type='radio'
              name='radioStepGreat'
              id='greatRadioThree'
              checked={isRadioChecked(survey?.data?.SurveyAnswers, 2)}
              onChange={handleCheckRadioThree}
            />
            <span className='radiobox__mark'></span>
            <span className='radiobox__title'>The food was delicious</span>
          </label>
          <label className='radiobox' htmlFor='greatRadioFour'>
            <input
              type='radio'
              name='radioStepGreat'
              id='greatRadioFour'
              checked={isRadioChecked(survey?.data?.SurveyAnswers, 3)}
              onChange={handleCheckRadioFour}
            />
            <span className='radiobox__mark'></span>
            <span className='radiobox__title'>Great value for money</span>
          </label>
        </div>
      </div>
    </>
  );
};
