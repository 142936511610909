import {v4 as uuidv4} from 'uuid';

class HeadersInterceptors {
  /**
   * @type {ILogger}
   */
  Logger;

  /**
   * Create a new HeadersInterceptors
   * @param Logger {ILogger}
   */
  constructor(Logger) {
    this.Logger = Logger;
  }

  /**
   * Request interceptor
   * @param config {import('axios').AxiosRequestConfig}
   * @return {import('axios').AxiosRequestConfig}
   */
  requestInterceptor = (config) => {
    config.headers['x-sauce-request-id'] = uuidv4();
    config.headers['x-sauce-session-id'] = this.Logger.sessionId;
    config.headers['x-sauce-client-id'] = this.Logger.clientId;

    return config;
  };
}

export default HeadersInterceptors;
