import React from "react";

const IconStar = () => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0418 80.9344C16.8148 81.4933 17.7205 81.7779 18.6325 81.7779C19.4201 81.7779 20.2007 81.5657 20.9013 81.1497L41.6235 68.7593L62.3457 81.1427C63.858 82.0516 65.7729 81.9716 67.209 80.9312C68.6482 79.8908 69.3215 78.0972 68.9289 76.3626L63.5734 52.7721L81.736 36.8472C83.0711 35.6817 83.5779 33.8328 83.0298 32.1471C82.4817 30.4614 80.9903 29.2642 79.2208 29.1048L55.1933 26.923L45.6922 4.68475C44.9916 3.05497 43.3962 2 41.6235 2C39.8508 2 38.2553 3.05497 37.5547 4.68856L28.0536 26.923L4.02233 29.1048C2.25663 29.268 0.765314 30.4614 0.217185 32.1471C-0.330944 33.8359 0.179076 35.6817 1.51415 36.8472L19.6767 52.7753L14.3212 76.3664C13.9293 78.0972 14.6019 79.8909 16.0418 80.9344Z"
      fill="transparent"
    />
    <g clipPath="url(#clip0_14338_107387)">
      <path
        d="M18.6325 81.564C17.7205 81.564 16.8148 81.2794 16.0418 80.7205C14.6019 79.677 13.9293 77.8833 14.3212 76.1526L19.6767 52.5614L1.51415 36.6333C0.179076 35.4678 -0.330944 33.6221 0.217185 31.9332C0.765314 30.2476 2.25663 29.0541 4.02233 28.8909L28.0536 26.7092L37.5547 4.4747C38.2553 2.84111 39.8508 1.78613 41.6235 1.78613C43.3962 1.78613 44.9916 2.84111 45.6922 4.47089L55.1933 26.7092L79.2208 28.8909C80.9903 29.0503 82.4817 30.2476 83.0298 31.9332C83.5779 33.6189 83.0711 35.4678 81.736 36.6333L63.5734 52.5583L68.9289 76.1488C69.3215 77.8833 68.6482 79.677 67.209 80.7173C65.7729 81.7577 63.858 81.8377 62.3457 80.9288L41.6235 68.5455L20.9013 80.9358C20.2007 81.3519 19.4201 81.564 18.6325 81.564ZM41.6235 63.2109C42.4111 63.2109 43.191 63.4224 43.8922 63.8384L63.4489 75.5352L58.3945 53.269C58.0337 51.6843 58.5717 50.0297 59.7962 48.9576L76.9457 33.9174L54.2565 31.857C52.6229 31.7078 51.218 30.6807 50.5796 29.1754L41.6235 8.19283L32.6565 29.1786C32.0252 30.6737 30.6202 31.7008 28.9905 31.85L6.29805 33.9104L23.4469 48.9506C24.6753 50.0259 25.2126 51.6773 24.8487 53.2658L19.798 75.5314L39.3547 63.8384C40.0553 63.4224 40.8359 63.2109 41.6235 63.2109ZM27.8701 27.1392C27.8701 27.1392 27.8701 27.143 27.8663 27.1462L27.8701 27.1392ZM55.3699 27.129L55.3737 27.136C55.3737 27.1322 55.3737 27.1322 55.3699 27.129Z"
        fill="#929CB9"
      />
    </g>
    <defs>
      <clipPath id="clip0_14338_107387">
        <rect width="83.2474" height="83.2474" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconStar;
