import {useQuery, useMutation, QueryClient} from 'react-query';
import * as api from '../api';

const useSurvey = (id, options = {}) => {
  return useQuery(['survey', id], () => api.getSurvey(id, options), options);
};

const queryClient = new QueryClient({});

const useUpdateSurvey = () => {
  return useMutation(api.updateSurvey, {
    onSuccess: async (_, {id, ...variables}) => {
      if (id !== 'demo') {
        await queryClient.refetchQueries(['survey', id]);
      }

      if (variables?.successCallback) {
        variables.successCallback();
      }
    },
    onError: (_, {...variables}) => {
      if (variables?.errorCallback) {
        variables.errorCallback();
      }
    }
  });
};

export {useSurvey, useUpdateSurvey};
