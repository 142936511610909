const DATA = {
  type: 'http-outgoing'
};

class LoggerInterceptor {
  /**
   * @type {ILogger}
   */
  Logger;

  /**
   * Create a new LoggerInterceptor
   * @param Logger {ILogger}
   */
  constructor(Logger) {
    this.Logger = Logger;
  }

  /**
   * Request interceptor
   * @param config {import('axios').AxiosRequestConfig}
   * @return {*} {import('axios').AxiosRequestConfig}
   */
  requestInterceptor = (config) => {
    config.metadata = {startTime: new Date()};

    this.Logger.logInfo('Request was sent', {
      ...DATA,
      ...LoggerInterceptor.getDataFromConfig(config)
    });

    return config;
  };

  /**
   * Request error interceptor
   * @param error {import('axios').AxiosError}
   * @return {Promise<never>}
   */
  requestErrorInterceptor = (error) => {
    this.Logger.logError(error, {
      ...DATA,
      ...LoggerInterceptor.getDataFromError(error)
    });

    return Promise.reject(error);
  };

  /**
   * Response interceptor
   * @param response {import('axios').AxiosResponse}
   * @return {*} {import('axios').AxiosResponse}
   */
  responseInterceptor = (response) => {
    response.config.metadata.endTime = new Date();

    const data = {
      ...DATA,
      ...LoggerInterceptor.getDataFromResponse(response)
    };

    if (response.status >= 200 && response.status < 300) {
      this.Logger.logInfo('Request was successful', data);
    } else if (response.status >= 400 && response.status < 500) {
      this.Logger.logWarning('Request was not successful', data);
    } else {
      this.Logger.logError(new Error('Request was not successful'), data);
    }

    return response;
  };

  responseErrorInterceptor = (error) => {
    this.Logger.logError(error, {
      ...DATA,
      ...LoggerInterceptor.getDataFromError(error)
    });

    return Promise.reject(error);
  };

  /**
   * Get data from response
   * @param response
   * @return {{duration: number, res: {headers, body}, http: {status_code, method: *, url: *}, req: {headers, body}}}
   */
  static getDataFromResponse = (response) => ({
    ...LoggerInterceptor.getDataFromConfig(response.config),

    http: {
      ...LoggerInterceptor.getDataFromConfig(response.config).http,
      status_code: response.status
    },

    res: {
      headers: response.headers,
      body: response.data
    },

    // in nanoseconds
    duration:
      (response.config.metadata.endTime - response.config.metadata.startTime) *
      1e6
  });

  /**
   * Get data from error
   * @param error {import('axios').AxiosError}
   * @return {{headers, method, data, statusText: *, url, status}}
   */
  static getDataFromError = (error) => ({
    ...LoggerInterceptor.getDataFromConfig(error.config),
    ...LoggerInterceptor.getDataFromResponse(error.response)
  });

  /**
   * Get data from axios config
   * @param config {import('axios').AxiosRequestConfig}
   * @return {{http: {url: *, method: *}, req: {headers, body}}}
   */
  static getDataFromConfig = (config) => ({
    http: {
      url: config.url,
      method: config.method.toUpperCase()
    },

    req: {
      headers: config.headers,
      body: config.data
    }
  });
}

export default LoggerInterceptor;
