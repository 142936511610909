import {useNavigate, useParams} from 'react-router-dom';
import {useSurvey, useUpdateSurvey} from '../../hooks';
import {generateSurveyAnswers} from '../../utils';

export const TwoStageBadDelivery = () => {
  const {surveyId} = useParams();
  const {data: survey} = useSurvey(surveyId);

  const navigate = useNavigate();
  const {mutate, isLoading} = useUpdateSurvey();

  let handleCheckRadioOne = () => {
    if (isLoading) return false;
    mutate({
      successCallback: () => navigate('../negative/stage-three'),
      id: surveyId,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'WhatWentWrong',
        0
      )
    });
  };
  let handleCheckRadioTwo = () => {
    if (isLoading) return false;
    mutate({
      successCallback: () => navigate('../negative/stage-three'),
      id: surveyId,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'WhatWentWrong',
        1
      )
    });
  };
  let handleCheckRadioThree = () => {
    if (isLoading) return false;
    mutate({
      successCallback: () => navigate('../negative/stage-three'),
      id: surveyId,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'WhatWentWrong',
        2
      )
    });
  };
  let handleCheckRadioFour = () => {
    if (isLoading) return false;
    mutate({
      successCallback: () => navigate('../negative/stage-three'),
      id: surveyId,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'WhatWentWrong',
        3
      )
    });
  };

  let isCheckedOne =
    survey?.data?.SurveyAnswers?.find((el) => el.Name === 'WhatWentWrong')
      ?.Value === 0;
  let isCheckedTwo =
    survey?.data?.SurveyAnswers?.find((el) => el.Name === 'WhatWentWrong')
      ?.Value === 1;
  let isCheckedThree =
    survey?.data?.SurveyAnswers?.find((el) => el.Name === 'WhatWentWrong')
      ?.Value === 2;
  let isCheckedFour =
    survey?.data?.SurveyAnswers?.find((el) => el.Name === 'WhatWentWrong')
      ?.Value === 3;
  return (
    <>
      <div className='survey__step secondStepBad'>
        <div className='survey__title lg'>Oh no!</div>
        <div className='survey__text'>Please share what went wrong?</div>
        <div className='survey__radioboxes'>
          <label className='radiobox'>
            <input
              type='radio'
              name='badRadioStep'
              id='badRadioOne'
              checked={isCheckedOne}
              onChange={handleCheckRadioOne}
            />
            <span className='radiobox__mark'></span>
            <span className='radiobox__title'>Missing items</span>
          </label>
          <label className='radiobox' htmlFor='badRadioTwo'>
            <input
              type='radio'
              name='badRadioStep'
              id='badRadioTwo'
              checked={isCheckedTwo}
              onChange={handleCheckRadioTwo}
            />
            <span className='radiobox__mark'></span>
            <span className='radiobox__title'>Packaging issues</span>
          </label>
          <label className='radiobox' htmlFor='badRadioThree'>
            <input
              type='radio'
              name='badRadioStep'
              id='badRadioThree'
              checked={isCheckedThree}
              onChange={handleCheckRadioThree}
            />
            <span className='radiobox__mark'></span>
            <span className='radiobox__title'>Food quality</span>
          </label>
          <label className='radiobox' htmlFor='badRadioFour'>
            <input
              type='radio'
              name='badRadioStep'
              id='badRadioFour'
              checked={isCheckedFour}
              onChange={handleCheckRadioFour}
            />
            <span className='radiobox__mark'></span>
            <span className='radiobox__title'>Delivery issues</span>
          </label>
        </div>
      </div>
    </>
  );
};
