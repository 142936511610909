import {datadogLogs} from '@datadog/browser-logs';
import ILogger from './logger.service.js';
import {v4 as uuidv4} from 'uuid';

/**
 * @class DataDog
 * @description DataDog logger
 * @implements ILogger
 */
class DataDog extends ILogger {
  /**
   * @type {string}
   */
  sessionId;
  /**
   * @type {string}
   */
  clientId;

  constructor() {
    super();

    this.clientId = DataDog.setClientId();

    datadogLogs.init({
      clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
      site: process.env.REACT_APP_DD_SITE || '',
      env: process.env.REACT_APP_ENV,
      service: process.env.REACT_APP_DD_SERVICE_NAME,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100
    });

    // need to be done after the init
    // this is DD session id, we will use it to track the user session
    this.sessionId = datadogLogs.getInternalContext().session_id;

    this.logger = datadogLogs.logger;

    datadogLogs.setGlobalContextProperty('sessionId', this.sessionId);
    datadogLogs.setGlobalContextProperty('clientId', this.clientId);
  }

  /**
   * Set the client id
   * @return {string}
   */
  static setClientId() {
    if (!localStorage.getItem('clientId')) {
      const uuid = uuidv4();

      localStorage.setItem('clientId', uuid);

      return uuid;
    } else {
      return localStorage.getItem('clientId');
    }
  }

  logError(error, data) {
    this.logger.error(
      error.message,
      {
        ...data,

        stack: error.stack
      },
      error
    );
  }

  logInfo(message, data) {
    this.logger.info(message, {
      ...data
    });
  }

  logWarning(message, data) {
    this.logger.warn(message, {
      ...data
    });
  }
}

export default DataDog;
