import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import cn from 'classnames';
import {useSurvey, useUpdateSurvey} from '../../hooks';
import IconStar from '../../images/iconStar';
import {generateSurveyAnswers} from '../../utils';

export const OneStagePickup = () => {
  const {surveyId} = useParams();
  const {data: survey} = useSurvey(surveyId);
  let navigate = useNavigate();
  const {mutate} = useUpdateSurvey();
  const [isHovered, setIsHovered] = useState({great: false, bad: false});
  const [overallExperienceRating, setOverallExperienceRating] = useState(
    survey?.data?.SurveyAnswers.find((el) => el.Name === 'OverallExperience')
      ?.Value
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOverallExperienceRating(
      () =>
        survey?.data?.SurveyAnswers.find(
          (el) => el.Name === 'OverallExperience'
        )?.Value
    );
    return () => setOverallExperienceRating(() => null);
  }, [survey?.data?.SurveyAnswers]);

  let handleClickStarOne = () => {
    setOverallExperienceRating(() => 1);
    mutate({
      successCallback: () => navigate('../negative/stage-two'),
      errorCallback: () => setLoading(false),
      id: surveyId,
      IsCompleted: false,
      EndTime: null,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'OverallExperience',
        1
      )
    });
  };
  let handleClickStarTwo = () => {
    setOverallExperienceRating(() => 2);
    mutate({
      successCallback: () => navigate('../negative/stage-two'),
      errorCallback: () => setLoading(false),
      id: surveyId,
      IsCompleted: false,
      EndTime: null,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'OverallExperience',
        2
      )
    });
  };
  let handleClickStarThree = () => {
    setOverallExperienceRating(() => 3);
    mutate({
      successCallback: () => navigate('../negative/stage-two'),
      errorCallback: () => setLoading(false),
      id: surveyId,
      IsCompleted: false,
      EndTime: null,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'OverallExperience',
        3
      )
    });
  };
  let handleClickStarFour = () => {
    setOverallExperienceRating(() => 4);
    mutate({
      successCallback: () => navigate('../positive/stage-two'),
      errorCallback: () => setLoading(false),
      id: surveyId,
      IsCompleted: false,
      EndTime: null,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'OverallExperience',
        4
      )
    });
  };
  let handleClickStarFive = () => {
    setOverallExperienceRating(() => 5);
    mutate({
      successCallback: () => navigate('../positive/stage-two'),
      errorCallback: () => setLoading(false),
      id: surveyId,
      IsCompleted: false,
      EndTime: null,
      SurveyAnswers: generateSurveyAnswers(
        survey.data.SurveyAnswers,
        'OverallExperience',
        5
      )
    });
  };

  return (
    <>
      <div className='survey__step firstStep'>
        <div className='survey__title'>
          Hi {survey?.data?.OrderData?.ClientName}, <br />
          Thanks for ordering from{' '}
          {survey?.data?.OrderData?.RestaurantBranchName ||
            survey?.data?.OrderData?.RestaurantName}
        </div>
        <div className='survey__text'>How was your overall experience?</div>
        <div className='survey__rating'>
          <div className='survey__rating-stars'>
            <input
              type='radio'
              id='5-star'
              name='rating-star'
              checked={overallExperienceRating === 5}
            />
            <label
              htmlFor='5-star'
              id='fiveStar'
              onMouseOver={() => {
                setOverallExperienceRating(() => 0);
                setIsHovered(() => ({great: true, bad: false}));
              }}
              onMouseLeave={() => {
                setIsHovered(() => ({great: false, bad: false}));
                setOverallExperienceRating(
                  () =>
                    survey?.data?.SurveyAnswers.find(
                      (el) => el.Name === 'OverallExperience'
                    )?.Value
                );
              }}
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  handleClickStarFive();
                }
              }}
              className={cn('survey__rating-star', {
                disabled: loading,
                great: isHovered.great || overallExperienceRating === 5,
                bad: isHovered.bad || overallExperienceRating <= 3
              })}
            >
              <IconStar />
            </label>
            <input
              type='radio'
              id='4-star'
              name='rating-star'
              checked={overallExperienceRating === 4}
            />
            <label
              htmlFor='4-star'
              id='fourStar'
              onMouseOver={() => {
                setOverallExperienceRating(() => 0);
                setIsHovered(() => ({great: true, bad: false}));
              }}
              onMouseLeave={() => {
                setIsHovered(() => ({great: false, bad: false}));
                setOverallExperienceRating(
                  () =>
                    survey?.data?.SurveyAnswers.find(
                      (el) => el.Name === 'OverallExperience'
                    )?.Value
                );
              }}
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  handleClickStarFour();
                }
              }}
              className={cn('survey__rating-star', {
                disabled: loading,
                great:
                  isHovered.great ||
                  overallExperienceRating === 4 ||
                  overallExperienceRating === 5,
                bad: isHovered.bad || overallExperienceRating <= 3
              })}
            >
              <IconStar />
            </label>
            <input
              type='radio'
              id='3-star'
              name='rating-star'
              checked={overallExperienceRating === 3}
            />
            <label
              htmlFor='3-star'
              id='threeStar'
              onMouseOver={() => {
                setOverallExperienceRating(() => 0);
                setIsHovered(() => ({great: false, bad: true}));
              }}
              onMouseLeave={() => {
                setIsHovered(() => ({great: false, bad: false}));
                setOverallExperienceRating(
                  () =>
                    survey?.data?.SurveyAnswers.find(
                      (el) => el.Name === 'OverallExperience'
                    )?.Value
                );
              }}
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  handleClickStarThree();
                }
              }}
              className={cn('survey__rating-star', {
                disabled: loading,
                great:
                  isHovered.great ||
                  overallExperienceRating === 4 ||
                  overallExperienceRating === 5,
                bad: isHovered.bad || overallExperienceRating <= 3
              })}
            >
              <IconStar />
            </label>
            <input
              type='radio'
              id='2-star'
              name='rating-star'
              checked={overallExperienceRating === 2}
            />
            <label
              htmlFor='2-star'
              id='twoStar'
              onMouseOver={() => {
                setOverallExperienceRating(() => 0);
                setIsHovered(() => ({great: false, bad: true}));
              }}
              onMouseLeave={() => {
                setIsHovered(() => ({great: false, bad: false}));
                setOverallExperienceRating(
                  () =>
                    survey?.data?.SurveyAnswers.find(
                      (el) => el.Name === 'OverallExperience'
                    )?.Value
                );
              }}
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  handleClickStarTwo();
                }
              }}
              className={cn('survey__rating-star', {
                disabled: loading,
                great:
                  isHovered.great ||
                  overallExperienceRating === 4 ||
                  overallExperienceRating === 5,
                bad: isHovered.bad || overallExperienceRating <= 3
              })}
            >
              <IconStar />
            </label>
            <input
              type='radio'
              id='1-star'
              name='rating-star'
              checked={overallExperienceRating === 1}
            />
            <label
              htmlFor='1-star'
              id='oneStar'
              onMouseOver={() => {
                setOverallExperienceRating(() => 0);
                setIsHovered(() => ({great: false, bad: true}));
              }}
              onMouseLeave={() => {
                setIsHovered(() => ({great: false, bad: false}));
                setOverallExperienceRating(
                  () =>
                    survey?.data?.SurveyAnswers.find(
                      (el) => el.Name === 'OverallExperience'
                    )?.Value
                );
              }}
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  handleClickStarOne();
                }
              }}
              className={cn('survey__rating-star', {
                disabled: loading,
                great:
                  isHovered.great ||
                  overallExperienceRating === 4 ||
                  overallExperienceRating === 5,
                bad: isHovered.bad || overallExperienceRating <= 3
              })}
            >
              <IconStar />
            </label>
          </div>
          <div className='survey__rating-label bad'>Bad</div>
          <div className='survey__rating-label great'>Great</div>
        </div>
      </div>
    </>
  );
};
