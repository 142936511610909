import {useState} from 'react';
import Routes from './pages/Routes';
import Preloader from './components/Preloader';
import './services/logger/datadog.instance';

function App() {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  return (
    <>
      {loading && <Preloader />}
      <Routes />
    </>
  );
}

export default App;
