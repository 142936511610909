import React, {useState} from 'react';
import T from 'prop-types';
import cn from 'classnames';
import ErrorSign from '../ErrorSign';

const UploadPhoto = ({setPhoto, orderId, photoError, setPhotoError}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const onFileChange = (e) => {
    const [file] = e.currentTarget.files;

    const allowedFileFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    const maxAllowedSize = 8 * 1024 * 1024;
    if (file && file.size > maxAllowedSize) {
      setPhotoError(`You cannot upload files larger than '8MB'`);
      return;
    }

    if (
      allowedFileFormats.length &&
      file &&
      !allowedFileFormats.includes(file.type)
    ) {
      setPhotoError(`Wrong file format. Formats allowed are: png, jpeg, jpg `);
      return;
    }

    if (file) {
      const fileForm = new FormData();
      fileForm.append('file', file);
      fileForm.append('orderId', orderId);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setPhoto(fileForm);
      setPhotoError(null);
    }
  };

  return (
    <div className='survey__uploadPhoto'>
      <label className='textLabel'>Upload a photo</label>
      <label
        htmlFor='userPhoto'
        className={cn('photoLabel', {
          error: photoError,
          withImg: selectedImage
        })}
      >
        {selectedImage && <img src={selectedImage} alt='user' />}
        <input id='userPhoto' type='file' onChange={onFileChange} />
      </label>
      {photoError && <ErrorSign text={<>{photoError}</>} />}
    </div>
  );
};

UploadPhoto.propTypes = {
  setPhoto: T.func.isRequired,
  orderId: T.string.isRequired,
  photoError: T.string.isRequired,
  setPhotoError: T.func.isRequired
};

export default UploadPhoto;
