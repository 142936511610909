import LoggerService from '../services/logger/datadog.instance.js';

import LoggerInterceptor from '../services/interceptors/logger.interceptors.js';
import HeadersInterceptor from '../services/interceptors/headers.interceptors.js';

const LoggerInterceptorInstance = new LoggerInterceptor(LoggerService);
const HeadersInterceptorInstance = new HeadersInterceptor(LoggerService);

/**
 * Add axios interceptors to instance
 * @param instance {import('axios').AxiosInstance}
 * @return {{remove: () => void}}
 */
const addAxiosInterceptorsToInstance = (instance) => {
  const interceptors = [];

  interceptors.push(
    instance.interceptors.request.use(
      (config) => LoggerInterceptorInstance.requestInterceptor(config),
      (error) => LoggerInterceptorInstance.requestErrorInterceptor(error)
    ),
    instance.interceptors.response.use(
      (response) => LoggerInterceptorInstance.responseInterceptor(response),
      (error) => LoggerInterceptorInstance.responseErrorInterceptor(error)
    ),
    instance.interceptors.request.use((config) =>
      HeadersInterceptorInstance.requestInterceptor(config)
    )
  );

  return {
    remove: () => {
      interceptors.forEach((interceptor) => {
        instance.interceptors.request.eject(interceptor);
        instance.interceptors.response.eject(interceptor);
      });
    }
  };
};

export default addAxiosInterceptorsToInstance;
