import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {useSurvey, useUpdateSurvey} from '../../hooks';
import {
  generatePlaceholder,
  generateSubtitle,
  generateSurveyAnswers,
  isNeedToAddPhoto
} from '../../utils';
import UploadPhoto from '../../components/UploadPhoto';
import {uploadPhoto} from '../../api';
import AnimatePoints from '../../components/AnimatePoints';
import ErrorSign from '../../components/ErrorSign';

export const LastStageBadPickup = () => {
  const {surveyId} = useParams();
  const {data: survey, isLoading} = useSurvey(surveyId);
  const {mutate} = useUpdateSurvey();
  const [sendMassage, setSendMassage] = useState({
    showForm: true,
    messageSent: 'Thank you!'
  });
  const [messageValue, setMessageValue] = useState('');
  const [photo, setPhoto] = useState(null);
  const [photoError, setPhotoError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validateField, setValidateField] = useState(true);

  useEffect(() => {
    setMessageValue(
      survey?.data?.SurveyAnswers.find((el) => el.Name === 'AdditionalComment')
        ?.Value
    );
  }, [isLoading]);

  let handleClickSendMassage = () => {
    if (messageValue?.trim()) {
      setLoading(true);
      if (!photoError) {
        if (photo) {
          uploadPhoto({
            id: surveyId,
            form: photo
          })
            .then((response) => {
              if (response.url) {
                window.history.pushState(null, null, window.location.href);
                window.onpopstate = function () {
                  window.history.go(1);
                };
                setSendMassage({
                  showForm: false,
                  messageSent: 'Message sent'
                });
                mutate({
                  id: surveyId,
                  IsCompleted: true,
                  EndTime: new Date(),
                  SurveyAnswers: generateSurveyAnswers(
                    survey.data.SurveyAnswers,
                    'AdditionalComment',
                    messageValue?.trim(),
                    response.url
                  )
                });
                setLoading(false);
              }
            })
            .catch(() => {
              setLoading(false);
              setPhotoError('Upload was unsuccessful');
            });
        } else {
          window.history.pushState(null, null, window.location.href);
          window.onpopstate = function () {
            window.history.go(1);
          };
          setSendMassage({
            showForm: false,
            messageSent: 'Message sent'
          });
          mutate({
            id: surveyId,
            IsCompleted: true,
            EndTime: new Date(),
            SurveyAnswers: generateSurveyAnswers(
              survey.data.SurveyAnswers,
              'AdditionalComment',
              messageValue?.trim() || null
            )
          });
          setLoading(false);
        }
      }
    } else {
      setValidateField(false);
    }
  };

  return (
    <>
      {survey?.data?.SurveyAnswers.find(
        (el) => el.Name === 'WhatWentWrong'
      ) && (
        <>
          <div className='survey__step lastStepBad'>
            <div className='survey__title lg marginTop60'>
              {!survey?.data?.SurveyAnswers.find(
                (el) => el.Name === 'AdditionalComment'
              )?.Value
                ? sendMassage.messageSent
                : 'Message sent'}
            </div>
            {sendMassage.showForm &&
              !survey?.data?.SurveyAnswers.find(
                (el) => el.Name === 'AdditionalComment'
              )?.Value && (
                <div>
                  <div className='survey__text'>
                    {generateSubtitle(
                      'pickup',
                      survey?.data?.SurveyAnswers.find(
                        (el) => el.Name === 'WhatWentWrong'
                      )?.Value
                    )}
                  </div>
                  {isNeedToAddPhoto(
                    'pickup',
                    survey?.data?.SurveyAnswers.find(
                      (el) => el.Name === 'WhatWentWrong'
                    )?.Value
                  ) ? (
                    <div className='survey__wrong'>
                      <div className='survey__field'>
                        <label htmlFor='answerFiled'>Your answer</label>
                        <textarea
                          required
                          name='comment'
                          id='answerFiled'
                          cols='30'
                          rows='10'
                          className='field-textarea'
                          onChange={(e) => {
                            setMessageValue(e.target.value);
                            setValidateField(true);
                          }}
                          value={messageValue}
                          placeholder={generatePlaceholder(
                            'pickup',
                            survey?.data?.SurveyAnswers.find(
                              (el) => el.Name === 'WhatWentWrong'
                            )?.Value
                          )}
                        ></textarea>
                        {!validateField && (
                          <ErrorSign text={<>Please write your comment</>} />
                        )}
                      </div>
                      <UploadPhoto
                        setPhoto={setPhoto}
                        orderId={survey.data.OrderId}
                        photoError={photoError}
                        setPhotoError={setPhotoError}
                      />
                    </div>
                  ) : (
                    <div className='survey__field'>
                      <label htmlFor='answerFiled'>Your answer</label>
                      <textarea
                        required
                        name='comment'
                        id='answerFiled'
                        cols='30'
                        rows='10'
                        className='field-textarea'
                        onChange={(e) => {
                          setMessageValue(e.target.value);
                          setValidateField(true);
                        }}
                        value={messageValue}
                        placeholder={generatePlaceholder(
                          'pickup',
                          survey?.data?.SurveyAnswers.find(
                            (el) => el.Name === 'WhatWentWrong'
                          )?.Value
                        )}
                      ></textarea>
                      {!validateField && (
                        <ErrorSign text={<>Please write your comment</>} />
                      )}
                    </div>
                  )}
                  <div className='survey__action'>
                    <button
                      className={cn('btn-red', {
                        disabled: loading
                      })}
                      onClick={handleClickSendMassage}
                    >
                      {loading ? (
                        <span>
                          Sending <AnimatePoints />
                        </span>
                      ) : (
                        <span>Send to management</span>
                      )}
                    </button>
                  </div>
                </div>
              )}
          </div>
        </>
      )}
    </>
  );
};
