import {Routes as Router, Route} from 'react-router-dom';
import SurveyPage from './index';
import {OneStagePickup} from './PickupSurveyPage/OneStagePickup';
import {TwoStageBadPickup} from './PickupSurveyPage/TwoStageBadPickup';
import {TwoStageGreatPickup} from './PickupSurveyPage/TwoStageGreatPickup';
import {LastStageBadPickup} from './PickupSurveyPage/LastStageBadPickup';
import {LastStageGreatPickup} from './PickupSurveyPage/LastStageGreatPickup';
import {OneStageDelivery} from './DeliverySurveyPage/OneStageDelivery';
import {TwoStageBadDelivery} from './DeliverySurveyPage/TwoStageBadDelivery';
import {TwoStageGreatDelivery} from './DeliverySurveyPage/TwoStageGreatDelivery';
import {LastStageBadDelivery} from './DeliverySurveyPage/LastStageBadDelivery';
import {LastStageGreatDelivery} from './DeliverySurveyPage/LastStageGreatDelivery';

function Routes() {
  return (
    <Router>
      <Route path={'pickup/:restaurantName/:surveyId'} element={<SurveyPage />}>
        <Route path='stage-one' element={<OneStagePickup />} />
        <Route path='negative/stage-two' element={<TwoStageBadPickup />} />
        <Route path='positive/stage-two' element={<TwoStageGreatPickup />} />
        <Route path='negative/stage-three' element={<LastStageBadPickup />} />
        <Route path='positive/stage-three' element={<LastStageGreatPickup />} />
      </Route>
      <Route
        path={'delivery/:restaurantName/:surveyId'}
        element={<SurveyPage />}
      >
        <Route path='stage-one' element={<OneStageDelivery />} />
        <Route path='negative/stage-two' element={<TwoStageBadDelivery />} />
        <Route path='positive/stage-two' element={<TwoStageGreatDelivery />} />
        {/*<Route path="stage-three" element={<ThreeStageDelivery />} />*/}
        {/*<Route path="negative/stage-four" element={<FourStageBadDelivery />} />*/}
        {/*<Route*/}
        {/*  path="positive/stage-four"*/}
        {/*  element={<FourStageGreatDelivery />}*/}
        {/*/>*/}
        <Route path='negative/stage-three' element={<LastStageBadDelivery />} />
        <Route
          path='positive/stage-three'
          element={<LastStageGreatDelivery />}
        />
      </Route>
    </Router>
  );
}

export default Routes;
