export const generateSurveyAnswers = (
  surveyArr,
  fieldName,
  fieldValue,
  photoUrl = null
) => {
  // Rewrite all previous answers
  if (fieldName === 'OverallExperience') {
    const result = [
      ...surveyArr.filter((el) => el.Name.includes('Delivery')),
      {Name: fieldName, Value: fieldValue}
    ];
    return fieldValue === 5
      ? result.filter((el) => el.Name !== 'AdditionalComment')
      : result;
  }

  if (fieldName === 'DeliveryOverallExperience') {
    return [
      ...surveyArr.filter((el) => !el.Name.includes('Delivery')),
      {Name: fieldName, Value: fieldValue}
    ].filter((el) => el.Name !== 'AdditionalComment');
  }

  if (surveyArr.find((el) => el.Name === fieldName)) {
    return surveyArr.map((el) => {
      if (el.Name === fieldName) {
        return {...el, Value: fieldValue};
      }
      return el;
    });
  }

  return [
    ...surveyArr,
    {Name: fieldName, Value: fieldValue, ...(photoUrl && {PhotoUrl: photoUrl})}
  ];
};

export const generateSubtitle = (type, value) => {
  if (type === 'pickup') {
    switch (value) {
      case 0:
        return 'We will pass this on to the restaurant';
      case 1:
        return 'Tell us what items were missing and upload a picture of the order you received';
      case 2:
        return 'Tell us what was wrong and upload a picture';
      case 3:
        return 'We apologize for any inconvenience. For food quality issues, we recommend you contact the restaurant directly';
      default:
        return 'Anything else you would like to share with us?';
    }
  }
  if (type === 'delivery') {
    switch (value) {
      case 0:
        return 'Tell us what items were missing and upload a picture of the order you received';
      case 1:
        return 'Tell us what was wrong and upload a picture';
      case 2:
        return 'We apologize for any inconvenience. For food quality issues, we recommend you contact the restaurant directly';
      case 3:
        return 'Could you explain what was the issue?';
      default:
        return 'Anything else you would like to share with us?';
    }
  }
};

export const generatePlaceholder = (type, value) => {
  if (type === 'pickup') {
    switch (value) {
      case 1:
        return 'Please share with us the missing items';
      case 2:
        return 'Please share with us your bad experience';
      case 3:
        return 'Please add here anything of relevance to your request';
      default:
        return 'Please write here anything that can help us improve our service';
    }
  }
  if (type === 'delivery') {
    switch (value) {
      case 0:
        return 'Please share with us the missing items';
      case 1:
        return 'Please share with us your bad experience';
      case 2:
        return 'Please add here anything of relevance to your request';
      case 3:
        return 'Please share what happened that prompted your low rating';
      default:
        return 'Please write here anything that can help us improve our service';
    }
  }
};

export const isNeedToAddPhoto = (type, value) => {
  if (type === 'pickup') {
    return value === 1 || value === 2 || value === 3;
  }
  if (type === 'delivery') {
    return value === 0 || value === 1 || value === 2;
  }
};
