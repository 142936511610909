import axios from 'axios';
import SauceLogo from '../images/sauce-logo.svg';
import addAxiosInterceptorsToInstance from '../helpers/addAxiosInterceptorsToInstance';

const SERVER_FEEDBACK_URL = `${process.env.REACT_APP_FEEDBACK_API_URL}`;
const SERVER_FEEDBACK_AUTH_TOKEM = `${process.env.REACT_APP_FEEDBACK_AUTH_TOKEN}`;

const client = axios.create({
  baseURL: `${SERVER_FEEDBACK_URL}/api/v1`
});

addAxiosInterceptorsToInstance(client);

const getSurvey = async (id, {variables}) => {
  if (id === 'demo') {
    return {
      data: {
        OrderData: {
          ClientName: 'Demo',
          RestaurantName: 'Demo',
          RestaurantLogoUrl: SauceLogo
        },
        SurveyAnswers: []
      }
    };
  }
  const {data} = await client.get(
    `/survey/${id}?IsExpired=${!!variables?.IsExpired}`,
    {
      headers: {
        'x-custom-auth': SERVER_FEEDBACK_AUTH_TOKEM
      }
    }
  );
  return data;
};

const updateSurvey = async ({id, ...product}) => {
  if (id === 'demo') return true;
  const {data} = await client.put(`/survey/${id}`, product, {
    headers: {
      'x-custom-auth': SERVER_FEEDBACK_AUTH_TOKEM
    }
  });
  return data;
};

const uploadPhoto = async ({id, form}) => {
  if (id === 'demo') return true;
  const {data} = await client.post('/survey/upload-photo', form, {
    headers: {
      'x-custom-auth': SERVER_FEEDBACK_AUTH_TOKEM,
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};

export {getSurvey, updateSurvey, uploadPhoto};
