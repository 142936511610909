/**
 * @interface ILogger
 * @description Interface for the logger
 */
class ILogger {
  /**
   * @type {string}
   */
  sessionId;
  /**
   * @type {string}
   */
  clientId;

  /**
   * Log an error
   * @param error {Error}
   * @param data {Object.<string, any> | undefined}
   */
  logError(error, data) {}

  /**
   * Log an info
   * @param message {string}
   * @param data {Object.<string, any> | undefined}
   */
  logInfo(message, data) {}

  /**
   * Log a warning
   * @param message {string}
   * @param data {Object.<string, any> | undefined}
   */
  logWarning(message, data) {}
}

export default ILogger;
