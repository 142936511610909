import {useNavigate, Outlet, useParams, useLocation} from 'react-router-dom';
import React, {useEffect} from 'react';
import {useState} from 'react';
import {useSurvey, useUpdateSurvey} from '../hooks';
import defaultLogo from '../images/icon-restaurant.svg';
import logoSauce from '../images/logo-sauce.svg';
import StageOneImage from '../images/step1.svg';
import StageTwoBadImage from '../images/step2-bad.svg';
import StageTwoGreatImage from '../images/step2-great.svg';
import StageThreeImage from '../images/step3.svg';
import StageLastImage from '../images/step-finish.svg';
import ErrorSign from '../components/ErrorSign';
import {clarityIdentify} from '../utils/identifyClarity';

const SurveyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {restaurantName, surveyId} = useParams();
  const [useQueryVars, setUseQueryVars] = useState({});
  const {
    data: survey,
    isLoading,
    refetch
  } = useSurvey(surveyId, {variables: useQueryVars});
  const {mutate} = useUpdateSurvey();
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);

  const findSurveyByName = (surveyArr = [], fieldName = '') =>
    surveyArr.find((el) => el.Name === fieldName);

  const redirectToLastFilledStep = () => {
    if (!isLoading && survey.data) {
      if (
        location.pathname.includes('/pickup') ||
        location.pathname.includes('/delivery')
      ) {
        const isNegativeSurveyPickup = findSurveyByName(
          survey?.data?.SurveyAnswers,
          'WhatWentWrong'
        );
        const isPositiveSurveyPickup = findSurveyByName(
          survey?.data?.SurveyAnswers,
          'LikeBest'
        );

        if (isNegativeSurveyPickup && survey?.data?.IsCompleted) {
          navigate('negative/stage-three');
        } else if (isPositiveSurveyPickup && survey?.data?.IsCompleted) {
          navigate('positive/stage-three');
        } else {
          navigate('stage-one');
        }
      }
      // else {
      //   const isNegativeDeliverySurvey = findSurveyByName(
      //       survey?.data?.SurveyAnswers,
      //       "DeliveryWhatWentWrong"
      //   );
      //   const isPositiveDeliverySurvey = findSurveyByName(
      //       survey?.data?.SurveyAnswers,
      //       "DeliveryLikeBest"
      //   );
      //   const isNegativeSurveyPickup = findSurveyByName(
      //       survey?.data?.SurveyAnswers,
      //       "WhatWentWrong"
      //   );
      //   const isPositiveSurveyPickup = findSurveyByName(
      //       survey?.data?.SurveyAnswers,
      //       "LikeBest"
      //   );
      //
      //   if (isNegativeDeliverySurvey && survey?.data?.IsCompleted) {
      //     navigate("negative/stage-five");
      //   } else if (isPositiveDeliverySurvey && survey?.data?.IsCompleted) {
      //     navigate("positive/stage-five");
      //   } else if (isPositiveSurveyPickup && !isPositiveDeliverySurvey) {
      //     navigate("positive/stage-two");
      //   } else if (isPositiveSurveyPickup && isPositiveDeliverySurvey) {
      //     navigate("positive/stage-four");
      //   } else if (isNegativeSurveyPickup && !isNegativeDeliverySurvey) {
      //     navigate("negative/stage-two");
      //   } else if (isNegativeSurveyPickup && isNegativeDeliverySurvey) {
      //     navigate("negative/stage-four");
      //   } else {
      //     navigate("stage-one");
      //   }
      // }
    }
  };

  if (survey?.data?.IsCompleted || location.pathname.includes('/stage-one')) {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }

  useEffect(() => {
    document.title = `${restaurantName} ${
      location.pathname.includes('/pickup') ? 'pickup' : 'delivery'
    } survey | Powered by Sauce`;

    if (survey?.data?.IsExpired) {
      setUseQueryVars({IsExpired: true});
    } else {
      redirectToLastFilledStep();
    }
    if (survey?.data) {
      clarityIdentify(
        survey?.data?.OrderData.RestaurantName,
        survey?.data?.OrderData.RestaurantBranchName,
        survey?.data?.LocationId
      );
    }

    if (!isLoading && !survey?.data?.IsExpired) {
      mutate({
        id: surveyId,
        IsViewed: true,
        ...(!survey?.data?.StartTime
          ? {StartTime: new Date()}
          : {StartTime: survey?.data?.StartTime})
      });
    }
  }, [isLoading]);

  const [stateStage, setStateStage] = useState({
    width: location.pathname.includes('/delivery') ? 50 : 50,
    surveyImageBg: '#4671DB',
    imgSurvey: StageOneImage,
    labelStep: 1,
    navigation: false,
    progressBar: true,
    navigationNext: true
  });

  const oneStepActionsPickup = () => {
    setStateStage(() => ({
      width: 50,
      surveyImageBg: '#4671DB',
      imgSurvey: StageOneImage,
      labelStep: 1,
      navigation: false,
      progressBar: true
    }));
  };
  const twoStepActionsPickupBad = () => {
    setStateStage(() => ({
      width: 100,
      surveyImageBg: '#FF2D38',
      imgSurvey: StageTwoBadImage,
      labelStep: 2,
      navigation: true,
      progressBar: true,
      navigationNext: true
    }));
  };
  const twoStepActionsPickupGreat = () => {
    setStateStage(() => ({
      width: 100,
      surveyImageBg: '#17C981',
      imgSurvey: StageTwoGreatImage,
      labelStep: 2,
      navigation: true,
      progressBar: true,
      navigationNext: true
    }));
  };
  const oneStepActionsDelivery = () => {
    setStateStage(() => ({
      width: 50,
      surveyImageBg: '#4671DB',
      imgSurvey: StageOneImage,
      labelStep: 1,
      navigation: false,
      progressBar: true
    }));
  };
  const twoStepActionsDeliveryBad = () => {
    setStateStage(() => ({
      width: 50,
      surveyImageBg: '#FF2D38',
      imgSurvey: StageTwoBadImage,
      labelStep: 2,
      navigation: true,
      progressBar: true,
      navigationNext: true
    }));
  };
  const twoStepActionsDeliveryGreat = () => {
    setStateStage(() => ({
      width: 50,
      surveyImageBg: '#17C981',
      imgSurvey: StageTwoGreatImage,
      labelStep: 2,
      navigation: true,
      progressBar: true,
      navigationNext: true
    }));
  };
  const threeStepActionsDelivery = () => {
    setStateStage(() => ({
      width: 75,
      surveyImageBg: '#4671DB',
      imgSurvey: StageThreeImage,
      labelStep: 3,
      navigation: true,
      progressBar: true,
      navigationNext: true
    }));
  };
  const fourStepActionsDeliveryBad = () => {
    setStateStage(() => ({
      width: 100,
      surveyImageBg: '#FF2D38',
      imgSurvey: StageTwoBadImage,
      labelStep: 4,
      navigation: true,
      progressBar: true,
      navigationNext: true
    }));
  };
  const fourStepActionsDeliveryGreat = () => {
    setStateStage(() => ({
      width: 100,
      surveyImageBg: '#17C981',
      imgSurvey: StageTwoGreatImage,
      labelStep: 4,
      navigation: true,
      progressBar: true,
      navigationNext: true
    }));
  };
  const lastStepActions = () => {
    setStateStage(() => ({
      surveyImageBg: '#17C981',
      imgSurvey: StageLastImage,
      progressBar: false,
      navigation: false
    }));
  };

  useEffect(() => {
    // Pickup Routes
    setIsErrorMessageDisplayed(false);
    if (
      location.pathname.includes('/pickup') &&
      location.pathname.includes('/stage-one')
    ) {
      oneStepActionsPickup();
    }
    if (
      !location.pathname.includes('/delivery') &&
      location.pathname.includes('/negative/stage-two')
    ) {
      twoStepActionsPickupBad();
    }
    if (
      !location.pathname.includes('/delivery') &&
      location.pathname.includes('/positive/stage-two')
    ) {
      twoStepActionsPickupGreat();
    }
    if (
      !location.pathname.includes('/positive') &&
      !location.pathname.includes('/negative') &&
      location.pathname.includes('/stage-three')
    ) {
      threeStepActionsDelivery();
    }
    if (location.pathname.includes('/negative/stage-four')) {
      fourStepActionsDeliveryBad();
    }
    if (location.pathname.includes('/positive/stage-four')) {
      fourStepActionsDeliveryGreat();
    }

    // Delivery Routes

    if (
      location.pathname.includes('/delivery') &&
      location.pathname.includes('/stage-one')
    ) {
      oneStepActionsDelivery();
    }
    if (
      !location.pathname.includes('/pickup') &&
      location.pathname.includes('/negative/stage-two')
    ) {
      twoStepActionsDeliveryBad();
    }
    if (
      !location.pathname.includes('/pickup') &&
      location.pathname.includes('/positive/stage-two')
    ) {
      twoStepActionsDeliveryGreat();
    }
    if (
      !location.pathname.includes('/delivery') &&
      location.pathname.includes('/negative/stage-two')
    ) {
      twoStepActionsPickupBad();
    }
    if (
      !location.pathname.includes('/delivery') &&
      location.pathname.includes('/positive/stage-two')
    ) {
      twoStepActionsPickupGreat();
    }
    // Last step
    if (
      location.pathname.includes('/negative/stage-three') ||
      location.pathname.includes('/positive/stage-three')
      // location.pathname.includes("/negative/stage-five") ||
      // location.pathname.includes("/positive/stage-five")
    ) {
      lastStepActions();
    }
  }, [location.pathname]);

  useEffect(() => {
    refetch();
  }, [useQueryVars]);

  let isCheckedWhatWentWrong = !!survey?.data?.SurveyAnswers?.find(
    (el) => el.Name === 'WhatWentWrong'
  );
  let isCheckedLikeBest = !!survey?.data?.SurveyAnswers?.find(
    (el) => el.Name === 'LikeBest'
  );
  let isCheckedDeliveryWhatWentWrong = !!survey?.data?.SurveyAnswers?.find(
    (el) => el.Name === 'DeliveryWhatWentWrong'
  );
  let isCheckedDeliveryLikeBest = !!survey?.data?.SurveyAnswers?.find(
    (el) => el.Name === 'DeliveryLikeBest'
  );
  let isCheckedDeliveryOverallExperienceBad =
    survey?.data?.SurveyAnswers?.find(
      (el) => el.Name === 'DeliveryOverallExperience'
    )?.Value < 5;
  let isCheckedDeliveryOverallExperienceGreat =
    survey?.data?.SurveyAnswers?.find(
      (el) => el.Name === 'DeliveryOverallExperience'
    )?.Value === 5;

  let handleClickPrev = () => {
    if (
      location.pathname.includes('/pickup') &&
      (location.pathname.includes('/negative/stage-two') ||
        location.pathname.includes('/positive/stage-two'))
    ) {
      navigate('stage-one');
      oneStepActionsPickup();
    }
    if (
      location.pathname.includes('/delivery') &&
      (location.pathname.includes('/negative/stage-two') ||
        location.pathname.includes('/positive/stage-two'))
    ) {
      navigate('stage-one');
      oneStepActionsDelivery();
    }
    if (
      location.pathname.includes('/pickup') &&
      location.pathname.includes('/negative/stage-three')
    ) {
      navigate('negative/stage-two');
      twoStepActionsPickupBad();
    }
    if (
      location.pathname.includes('/pickup') &&
      location.pathname.includes('/positive/stage-three')
    ) {
      navigate('positive/stage-two');
      twoStepActionsPickupBad();
    }

    // if (
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/negative/stage-five")
    // ) {
    //   navigate("negative/stage-four");
    //   fourStepActionsDeliveryBad();
    // }
    // if (
    //     (isCheckedLikeBest || surveyId === "demo") &&
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/stage-three")
    // ) {
    //   navigate("positive/stage-two");
    //   fourStepActionsDeliveryGreat();
    // }

    // if (
    //     (isCheckedWhatWentWrong || surveyId === "demo") &&
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/stage-three")
    // ) {
    //   navigate("negative/stage-two");
    //   twoStepActionsDeliveryBad();
    // }
    // if (
    //     location.pathname.includes("/delivery") &&
    //     (location.pathname.includes("/negative/stage-four") ||
    //         location.pathname.includes("/positive/stage-four"))
    // ) {
    //   navigate("stage-three");
    //   threeStepActionsDelivery();
    // }
    // if (
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/negative/stage-five")
    // ) {
    //   navigate("negative/stage-four");
    //   fourStepActionsDeliveryBad();
    // }
    // if (
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/positive/stage-five")
    // ) {
    //   navigate("positive/stage-four");
    //   fourStepActionsDeliveryGreat();
    // }
  };

  let handleClickNext = () => {
    if (
      isCheckedWhatWentWrong &&
      location.pathname.includes('/pickup') &&
      location.pathname.includes('/negative/stage-two')
    ) {
      navigate('negative/stage-three');
      lastStepActions();
    }
    if (
      isCheckedLikeBest &&
      location.pathname.includes('/pickup') &&
      location.pathname.includes('/positive/stage-two')
    ) {
      navigate('positive/stage-three');
      lastStepActions();
    }
    if (
      isCheckedWhatWentWrong &&
      location.pathname.includes('/delivery') &&
      location.pathname.includes('/negative/stage-two')
    ) {
      navigate('negative/stage-three');
      lastStepActions();
    }

    if (
      isCheckedLikeBest &&
      location.pathname.includes('/delivery') &&
      location.pathname.includes('/positive/stage-two')
    ) {
      navigate('positive/stage-three');
      lastStepActions();
    }
    // if (
    //     isCheckedDeliveryOverallExperienceBad &&
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/stage-three")
    // ) {
    //   navigate("negative/stage-four");
    //   fourStepActionsDeliveryBad();
    // }
    // if (
    //     isCheckedDeliveryOverallExperienceGreat &&
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/stage-three")
    // ) {
    //   navigate("positive/stage-four");
    //   fourStepActionsDeliveryGreat();
    // }
    // if (
    //     isCheckedDeliveryWhatWentWrong &&
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/negative/stage-four")
    // ) {
    //   navigate("negative/stage-five");
    //   lastStepActions();
    // }
    // if (
    //     isCheckedDeliveryLikeBest &&
    //     location.pathname.includes("/delivery") &&
    //     location.pathname.includes("/positive/stage-four")
    // ) {
    //   navigate("positive/stage-five");
    //   lastStepActions();
    // }

    setIsErrorMessageDisplayed(true);
  };
  return (
    <>
      <div className='survey'>
        <div
          className='survey__image'
          style={{backgroundColor: `${stateStage.surveyImageBg}`}}
        >
          <img src={stateStage.imgSurvey} alt='' />
        </div>
        <div className='survey__content'>
          <div className='survey__container'>
            <div className='survey__logo'>
              <div className='survey__logo-wrapper'>
                <img
                  src={
                    survey?.data?.OrderData?.RestaurantLogoUrl
                      ? survey?.data?.OrderData?.RestaurantLogoUrl
                      : defaultLogo
                  }
                  className='restaurantLogo'
                  alt=''
                />
              </div>
            </div>
            <div className='survey__form'>
              {!survey?.data?.IsExpired && stateStage.navigation && (
                <div className='survey__nav'>
                  <div
                    className='survey__arrow survey__arrow--prev'
                    onClick={handleClickPrev}
                  ></div>
                  {stateStage.navigationNext && (
                    <div
                      className='survey__arrow survey__arrow--next'
                      onClick={handleClickNext}
                    ></div>
                  )}
                </div>
              )}
              <div className='survey__name'>
                {survey?.data?.OrderData?.RestaurantBranchName ||
                  survey?.data?.OrderData?.RestaurantName}
              </div>
              {stateStage.progressBar && !survey?.data?.IsExpired && (
                <div className='survey__progress'>
                  <div className='survey__progress-label labelStep'>
                    Step {stateStage.labelStep}
                  </div>
                  <div className='survey__progress-bar'>
                    <span style={{width: `${stateStage.width}%`}}></span>
                  </div>
                </div>
              )}
              {!survey?.data?.IsExpired && (
                <Outlet context={[setStateStage, handleClickNext]} />
              )}
              {survey?.data?.IsExpired && (
                <>
                  <div className='survey__title'>
                    Hi {survey?.data?.OrderData?.ClientName}, <br />
                    Thanks for ordering from{' '}
                    {survey?.data?.OrderData?.RestaurantName}
                  </div>
                  <div className='survey__expired'>
                    <div className='survey__subtitle'>
                      Sorry, the survey is not available anymore
                    </div>

                    {survey?.data?.OrderingWebsiteUrl && (
                      <a
                        href={`${survey?.data?.OrderingWebsiteUrl}`}
                        className='survey__link'
                      >
                        Back to menu
                      </a>
                    )}
                  </div>
                </>
              )}

              {isErrorMessageDisplayed && (
                <ErrorSign
                  text={
                    <>
                      Please select one of the options above before proceeding
                      to the next page
                    </>
                  }
                />
              )}
            </div>
            <div className='survey__bottom'>
              <span>Powered by</span>
              <img src={logoSauce} alt='' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyPage;
