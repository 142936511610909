export function clarityIdentify(merchantName, locationName, locationId) {
  if (
    typeof window.clarity === 'function' &&
    (merchantName || locationName || locationId)
  ) {
    try {
      if (merchantName) {
        window.clarity('set', 'merchantName', merchantName);
      }
      if (locationName) {
        window.clarity('set', 'locationName', locationName);
      }
      if (locationId) {
        window.clarity('set', 'locationId', locationId);
      }
    } catch (error) {
      console.error('Clarity Error', error);
    }
  }
}
