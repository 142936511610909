import CircularProgress from '@material-ui/core/CircularProgress';

const Preloader = () => {
  return (
    <div className='PreloaderWrapper'>
      <CircularProgress className='CircularProgress' />
    </div>
  );
};

export default Preloader;
