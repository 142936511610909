import React from 'react';
import cn from 'classnames';

const AnimatePoints = () => {
  return (
    <div className={cn('animate-points')}>
      <span className='point-one'>.</span>
      <span className='point-two'>.</span>
      <span className='point-three'>.</span>
    </div>
  );
};

export default AnimatePoints;
